import type { QueryResult } from "react-query";
import { useQuery } from "react-query";
import type { AxiosResponse } from "axios";
import axios from "axios";
import type { Client } from "types/model/client";

const getClient = async (): Promise<Client> => {
  const response: AxiosResponse<Client> = await axios.get("/api/client");
  return response.data;
};

export default function useClient(): QueryResult<Client> {
  return useQuery(["client"], getClient);
}
