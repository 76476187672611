export const isProtectedRoute = (pathName: string): boolean => {
  return (
    pathName.startsWith("/account") ||
    pathName.startsWith("/admin") ||
    (pathName.startsWith("/checkout") && !pathName.startsWith("/checkout/user"))
  );
};

export const isAdminRoute = (pathName: string): boolean => {
  return pathName.startsWith("/admin");
};

export const getShouldNotRetry = (statusCode: number): boolean => {
  return statusCode === 401 || statusCode === 403 || statusCode === 404;
};
