import type Stripe from "stripe";
import type { Booking } from "types/model/booking";
import type { UserSubscription } from "./user-subscription";
import type { StripeConnectedAccount } from "types/model/client";

export enum PaymentProviderName {
  Stripe = "stripe",
  Offline = "offline",
  ChildcareVoucher = "childcareVoucher",
  TaxFreeChildcare = "taxFreeChildcare"
}

export interface PaymentProvider {
  _id: string;
  name: string;
  description: string;
  enabled: boolean;
  internalName: PaymentProviderName;
}

export interface PaymentMethodInstance {
  _id: string;
  name: string;
  message: string;
  weight: number;
  enabled: boolean;
  provider: PaymentProvider;
  client: string;
}

export interface PaymentTransaction {
  _id: string;
  amount: number;
  status: PaymentTransactionStatus;
  user: string;
  booking: string;
  method: PaymentMethodInstance;
  ccvProvider?: CcvProvider;
  tfcReference?: string;
  stripePaymentIntentId?: string;
  created: string;
  updated: string;
  client: string;
  type: PaymentTransactionType;
  stripePaymentMethodDetails?: Stripe.Charge.PaymentMethodDetails;
  deleteOne: () => Promise<void>;
  save: () => Promise<void>;
  toObject: () => PaymentTransaction;
}

export interface PaymentTransactionCreateData {
  bookingId?: string;
  ccvProviderId?: string;
  method?: string;
  amount?: number;
  tfcReference?: string;
}

export interface CcvProvider {
  _id: string;
  name: string;
  paymentInstructions: string;
  enabled: boolean;
}

export interface TfcReferences {
  [key: string]: string;
}

export enum PaymentTransactionStatus {
  Pending = "pending",
  Completed = "completed"
}

export enum PaymentTransactionType {
  Charge = "charge",
  Refund = "refund"
}

export enum PaymentType {
  Checkout = "checkout",
  Additional = "additional",
  PaymentLink = "paymentLink",
  AccountPayment = "accountPayment",
  SessionPassPurchase = "sessionPassPurchase"
}

export interface PaymentLink<B = Booking, U = string, C = string> {
  _id: string;
  token: string;
  booking: B;
  user: U;
  client: C;
  maxPayment?: number;
  toObject: () => PaymentLink;
}

export interface UserSavedCard extends Stripe.PaymentMethod {
  isDefault: boolean;
  offSessionSetUp: boolean;
  associatedSubscriptions: UserSubscription[];
}

export interface StripeConnectedAccountWithStripeAccount
  extends StripeConnectedAccount {
  stripeAccount: Stripe.Account;
}

export interface PaymentMethodData {
  paymentMethod: PaymentMethodInstance;
  stripeConnectedAccounts?: StripeConnectedAccountWithStripeAccount[];
  clientHasActiveSubscriptions?: boolean;
}
