import axios from "axios";
import type { MutationResultPair} from "react-query";
import { useMutation } from "react-query";
import type { ErrorResponse } from "types";

interface SendVerificationEmailResponse {
  data: {
    success: boolean;
    email: string;
  };
}

export default function useSendVerificationEmail(): MutationResultPair<
  SendVerificationEmailResponse,
  ErrorResponse,
  void,
  unknown
> {
  return useMutation(() => {
    return axios.post("/api/user/send-verification-email").catch(err => {
      throw new Error(err.response.data.message);
    });
  });
}
