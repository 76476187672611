import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { isProtectedRoute } from "helpers/auth";
import { useRouter } from "next/router";
import type { MutationResultPair} from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";

export default function useDeleteAuth(): MutationResultPair<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  null,
  unknown
> {
  const router = useRouter();

  return useMutation(
    () => {
      return axios.delete("/api/auth");
    },
    {
      onSuccess: async () => {
        queryCache.invalidateQueries("currentUser");
        queryCache.invalidateQueries("basket");
        if (isProtectedRoute(router.pathname)) {
          router.push("/");
        }
        if (window["Beacon"] && window["Beacon"]("info")) {
          window["Beacon"]("destroy");
        }
      }
    }
  );
}
